.enkrateia-kliparo-question {
  margin-top: 1em;
  margin-bottom: 1em;

  .question-label {
    display: flex;
    align-items: center;

    .rc-tooltip-trigger-wrapper {
      margin-left: 10px;
      margin-top: 6px;
    }
  }

  .url-question textarea {
    font-size: 12pt !important;
  }
}
