.enkrateia-element-page .enkrateia-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 30px 10px;

  color: white;

  span {
    text-align: center;
    padding-top: 10px;
  }

  button {
    width: 100%;
    min-height: 51px;
    display: flex;
    align-items: center;
  }

  a {
    margin-top: 15px;
    color: white;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
      margin-right: 10px;
      margin-bottom: 4px;
    }
  }
}
