@import "transitions";
@import "fonts";
@import "colors";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Hanuman', sans-serif;
  font-style: normal;
}

a {
  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;

  text-decoration-line: underline;

  color: $primaryBlue100;
}

.flex-grower {
  flex: 1;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}
