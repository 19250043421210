.enkrateia-project-info {
  padding: 30px;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;

  .project-name-edit-icon {
    margin-right: 5px;
  }

  & > .span-2-column {
    grid-column: span 2;
  }

  .enkrateia-project-info-panel.with-border {
    .label {
      white-space: nowrap;
    }
  }
}
