.enkrateia-project-overview-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    main {
      width: 100%;
      overflow-y: auto;
    }
  }
}
