@import "src/styles/colors.scss";

.enkrateia-overview-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .enkrateia-table {
    align-self: flex-start;
  }

  .enkrateia-header {
    width: 100%;
  }

  .enkrateia-footer {
    padding: 16px;
    background: white;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > .summary {
      max-width: 940px;
    }

    & > .title {
      max-width: 1400px;
    }

    & > .title {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      width: 100%;
    }

    & > .data {
      border-top: 1px solid #DEDEDE;
      width: 100%;
      padding: 13px 0;
      margin: 0 10px;
      display: flex;
      justify-content: flex-start;

      &:not(.reviewer) {
        max-width: 1400px;
      }

      &.reviewer {
        max-width: 1800px;
      }

      & > table {
        .remedial-action-row td {
          background-color: $secondaryBlue90;
          color: white;

          a, a span {
            color: white !important;
          }

          a path {
            fill: white !important;
          }
        }

        .remedial-action-row td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        .remedial-action-row td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    .summary {
      margin-top: 40px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

}
