@import "src/styles/colors";
@import "src/styles/fonts";

.enkrateia-select-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    min-width: 100px;
  }

  &.full-width > *, &.full-width input {
    width: 100%;
  }

  label {
    margin-bottom: 7px;
    text-transform: uppercase;
    font-family: 'Hanuman', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    color: $primaryBlue100;
  }

  .enkrateia-react-select-container {

    .react-select__single-value,
    .react-select__input-container input,
    .react-select__option {
      font-family: 'Montserrat', serif !important;
    }

    .react-select__single-value,
    .react-select__input-container input {
      color: black !important;
    }

    .react-select__value-container {
      padding: 2px 8px 2px 0;

      .react-select__single-value {
        color: #3C3C3C;
      }
    }

    .react-select__control {
      border: none;
      border-bottom: 2px solid $accentLight;
      border-radius: 0;

      &.react-select__control--is-focused {
        box-shadow: none;
      }
    }

    .react-select__indicator {
      padding: 8px 0 8px 8px;

      path {
        fill: #3C3C3C;
        scale: 1.3;
        translate: -3px -3px;
      }
    }

    .react-select__indicator-separator {
      display: none;
    }
  }

  .react-select__single-value.react-select__single-value--is-disabled {
    color: #999999 !important;
  }

  input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    padding: 8px 0;
    font-size: 12pt;
    font-family: Hanuman sans-serif;
  }

  .error-label {
    color: $errorTextColor;
  }

  &.inline {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-bottom: 0;
      text-align: end;
      margin-right: 15px;
    }
  }

  label {
    display: flex;
    align-items: center;

    .rc-tooltip-trigger-wrapper {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &.variant-outlined {
    label {
      font-weight: 400;
      font-size: 18px;
      color: #000;

      text-transform: none;
    }

    .react-select__control {
      border: 1px solid #D9D9D9;
      border-radius: 0;
    }

    .react-select__value-container {
      padding: 12px 15px 7px 15px;
    }
    .react-select__indicator {
      padding: 8px 15px 8px 8px;

      svg path {
        fill: #E4E4E4;
      }
    }
  }

  &.variant-filled {
    label {
      margin-bottom: 7px;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000;

      text-transform: none;
    }

    .react-select__control {
      border: 1px solid #D0D4D9;
      border-radius: 0;
    }

    .react-select__value-container {
      padding: 8px 6px 4px 6px;

    }

    .react-select__input-container {
      margin: 0;
      padding: 0;
    }

    .react-select__indicator {
      padding: 8px 15px 4px 8px;

      svg path {
        fill: #E4E4E4;
      }
    }
  }

  &.variant-raw {
    label {
      display: none;
    }

    .enkrateia-react-select-container {
      width: 100%;
    }

    .react-select__control {
      border: none;
      border-radius: 0;
      background: transparent;
    }

    .react-select__value-container {
      padding: 8px 6px 4px 6px;
    }

    .react-select__input-container {
      margin: 0;
      padding: 0;
    }

    .react-select__indicator {
      padding: 8px 15px 4px 8px;

      svg path {
        fill: #E4E4E4;
      }
    }

    .react-select__single-value,
    .react-select__input-container,
    .react-select__input-container input {
      font-family: "Hanuman", sans-serif !important;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 31px;
      background: transparent;
      border: none;
      color: white !important;
      resize: none;
      outline: none;
      box-shadow: none;
    }
  }
}
