@import "src/styles/colors";

.enkrateia-header {
  max-height: 60px;
  border-bottom: 1px solid hsla(0, 0%, 92%, 1);

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px 5px;

  .logo {
    display: flex;
    flex-direction: row;

    align-items: center;
    padding: 8px 8px 10px 8px;

    .title {
      font-size: 24px;
      line-height: 35px;
      text-transform: capitalize;

      margin-left: 21px;
      margin-top: 10px;
      color: $primaryBlue100;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    a, span.nav-element {
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-decoration: none;
      color: #2c2b63;
      cursor: pointer;
    }

    .nav-element {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 25px;
      margin-right: 25px;

      img {
        margin-right: 10px;
        height: 28px;
      }

      svg {
        margin-right: 10px;
        margin-bottom: 6px;
      }

      &.disabled {
        color: #2D2C6499;
      }
    }

    button.nav-element {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 5px;
      border-radius: 50%;

      img {
        margin-right: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
