.enkrateia-rating-help-content {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 40px;
  grid-row-gap: 18px;

  align-items: center;
  padding: 15px 21px;
  max-width: 690px;

  &,
  & > .enkrateia-review-rating-bulb .with-label {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: black;
  }

  .enkrateia-review-rating-bulb .with-label {
    margin-left: 40px;
  }
}
