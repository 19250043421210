.enkrateia-kliparo-element-submitted-modal {
  position: relative;

  .enkrateia-kliparo-modal {
    min-width: 300px;

    .enkrateia-button {
      max-width: 300px;
      min-width: 300px;
    }

    .modal-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      flex-direction: column;


      & > * {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .assessment-complete-info {
        border-top: 3px solid #2c2b63;
        margin: 15px -15px 15px -15px;
        padding: 5px 15px;

        display: flex;
        flex-direction: column;
        max-width: 500px;

        align-items: center;

        p {
          text-align: center;
        }
      }
    }
  }

  .enkrateia-loader-overlay {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 10px;
  }
}
