.enkrateia-go-back-panel {
  height: 70px;
  display: flex;
  align-items: center;

  a {
    padding: 20px 30px;
    color: white;
    display: inline-flex;
    align-items: center;
    font-size: 16px;

    img {
      margin-right: 5px;
    }
  }

  border-bottom: 1px solid #306780;
}
