.enkrateia-splash {
  flex: 2;
  position: relative;
  background-image: url("../../../../assets/img/loginSplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .content {
    position: relative;
    z-index: 1;
    margin-top: 150px;

    p.cta {
      max-width: 820px;
      color: white;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  & > .footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    color: white;
    font-family: Montserrat, sans-serif;
    display: grid;
    grid-row-gap: 15px;
    grid-template-rows: auto auto auto;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 15px;

    p {
      margin: 0;
    }
  }
}
