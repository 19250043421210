.enkrateia-problem-springer-arc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
  padding-bottom: 30px;

  svg path {
    fill: #1d5268;

    &.active {
      fill: #d99f50;
    }
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    margin-top: 30px;
    text-decoration: underline;
    color: white;
  }

  .hint {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;

    color: #97EEFF;
  }
}
