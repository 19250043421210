.enkrateia-ticket-id-cell {
  color: #4A4A4A;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  font-weight: 400;
  font-size: 18px;

  svg {
    margin-right: 4px;
    margin-bottom: 4px;
    width: 18px;
    height: 18px;
  }
}
