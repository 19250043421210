.enkrateia-rfp-response-status-indicator {
  .divider {
    margin: 0 10px;
  }

  .active {
    color: #fff;
    text-decoration: underline;
  }

  :not(.active) {
    color: #D8DADF;
  }
}
