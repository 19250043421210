@import "src/styles/colors";

.enkrateia-scoring-rubric-row {
  &.scoring-range {
    text-align: right;
    padding-right: 4px;
  }

  &.description {
    text-align: left;
  }

  &.active {
    font-weight: 600;

    &.Green {
      color: $scoringGreen;
    }

    &.Yellow {
      color: $scoringYellow;
    }

    &.Red {
      color: $scoringRed;
    }
  }
}
