.enkrateia-dashboard-recent-tickets {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
      }

      a {
        margin-left: 10px;
        margin-top: -2px;
        color: #4A4A4A;

        svg {
          margin-bottom: -2px;
        }
      }
    }

    .enkrateia-button.variant-link {
      margin-bottom: 0;
    }
  }

  .enkrateia-table {
    align-self: flex-start;
  }
}
