@import "src/styles/colors";

.enkrateia-element-background {
  padding: 30px;
  display: flex;
  flex-direction: column;

  .context-0 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;

    color: $secondaryBlue90;
  }

  .context-1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .enkrateia-note {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin: 0;
      color: black;
    }
  }

  .enkrateia-button {
    position: relative;
    align-self: center;
    margin-top: 80px;
    padding-left: 45px;
    padding-right: 45px;
  }
}
