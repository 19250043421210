.enkrateia-history-comment-entry {
  display: flex;
  flex-direction: column;

  & > .comment {
    font-size: 16px;
    color: #306780;
    font-style: italic;
  }
}
