@import "src/styles/colors";

.enkrateia-editable-project-info-panel {
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  justify-content: flex-start;
  text-align: left;
  position: relative;

  .label button {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }

  &.variant-traro {
    &.with-border {
      border: 1px solid $traroTertiary500;
    }

    .label {
      color: $traroTertiary500;

      svg path {
        fill: $traroTertiary500;
      }
    }
  }

  &.variant-kliparo {
    &.with-border {
      border: 1px solid black;
    }

    .label {
      color: black;
      padding-left: 5px;

      svg path {
        fill: black;
      }
    }
  }

  &.with-border {
    border-radius: 6px;
    align-items: center;
    padding: 5px;
  }

  .label {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    .enkrateia-svg-icon {
      margin-right: 10px;
    }
  }

  .value {
    margin: -5px -10px;
    padding: 5px 10px;

    &:not(:disabled):hover {
      background: rgba(0, 0, 0, 0.2);
    }

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      appearance: none;
    }

    &:focus {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
    align-items: stretch;

    & > button {
      flex: 1;
      margin: 10px 5px;
      padding: 5px;
    }
  }
}
