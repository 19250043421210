.enkrateia-arch-keystone-text {
  fill: rgba(255, 255, 255, 0.5);

  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}
