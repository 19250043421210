@import "src/styles/colors";

.enkrateia-project-info-panel {
  display: flex;
  flex-direction: column;

  &.variant-traro {
    &.with-border {
      border: 1px solid $traroTertiary500;
    }

    .label {
      color: $traroTertiary500;
    }
  }

  &.variant-kliparo {
    &.with-border {
      border: 1px solid black;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .label {
      color: black;
    }
  }


  &.with-border {
    border-radius: 6px;
    align-items: center;
    padding: 5px;
  }

  .label {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .value, .value > * {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 31px;

    color: white;
  }
}
