.fade-from-top-enter {
  opacity: 0.5;
  transform: translate(0, -25px);
  z-index: 1;
}

.fade-from-top-enter.fade-from-top-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-from-top-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-from-top-exit.fade-from-top-exit-active {
  opacity: 0.5;
  transform: translate(0, -30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

.fade-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  transition: opacity 250ms ease-out;
}


.scale-y-enter {
  opacity: 1;
  transform: scaleX(0);
}

.scale-y-enter.scale-y-enter-active {
  opacity: 1;
  transform: scaleX(1);
  transition: transform 150ms ease-out, opacity 250ms ease-out;
}

.scale-y-exit {
  opacity: 1;
  transform: scaleX(1);
}

.scale-y-exit.scale-y-exit-active {
  position: absolute;
  transform: scaleX(0);
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: transform 150ms ease-out, opacity 250ms ease-out;
}
