.enkrateia-reviewer-actions {
  padding: 0 30px;
  margin-right: 45px;

  display: flex;
  flex-direction: row;
  gap: 15px;

  .plus-icon {
    margin-top: 1px;
    margin-right: 10px;
  }
}

.enkrateia-reviewer-actions .revert-to-open,
.enkrateia-change-assessment-state-modal .revert-to-open {
  border-width: 1px !important;
}
