@import "src/styles/colors";

.enkrateia-rfp-response-completion-percentage-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .bar {
    width: 100px;
    height: 14px;

    border: 1px solid #000000;
    border-radius: 14px;
    margin-top: -4px;
    margin-right: 10px;

    & > .fill {
      height: 100%;
      border-radius: 14px;
    }
  }

  &.Open > .bar > .fill {
    background: $secondaryBlue90;
  }

  &.In.Review > .bar > .fill {
    background: $darkGreen100;
  }

  &.Final > .bar > .fill {
    background: $grey100;
  }
}
