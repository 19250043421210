@import "src/styles/colors";

.enkrateia-note {
  &.variant-traro {
    background-color: $infoBackground;
    color: $traroSecondary500;
  }

  &.variant-kliparo {
    background-color: rgba(125, 133, 150, 0.3);
    color: $primaryBlue100;
  }

  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;

  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
      margin-right: 10px;
    }
  }
}
