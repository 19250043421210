@import "src/styles/colors";

.enkrateia-splash-form-wrapper {
  flex: 1;
  display: flex;
  max-width: 460px;
  flex-direction: column;
  justify-content: center;

  .app-platform {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin: 20px 0 0 0;

    color: $primaryBlue100;
  }

  .form-container {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 70px;
    display: flex;
    flex: 1;

    & > img {
      max-width: 460px;
      margin-left: -70px;
      margin-right: -70px;
    }

    h2 {
      margin-top: 25px;
      margin-bottom: 0;
    }
  }

  .footer {
    margin-top: 160px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-us {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-bottom: 4px;

      p {
        margin-top: 4px;
        margin-bottom: 0;
      }

      p, a {
        font-size: 16px;
        line-height: normal;
      }
    }

    p, a {
      font-size: 12px;
      line-height: 18px;
    }

    .enkrateia-footer {
      margin-top: 6px;
    }
  }
}
