@import "src/styles/colors";

.enkrateia-ticket-form {
  .header {
    border-bottom: 1px solid #DEDEDE;
    padding: 5px 15px !important;

    h2 {
      margin: 0;
      padding: 10px 5px 7px 15px;
      width: 100%;
    }

    .max-width {
      max-width: 100%;
    }

    &.header-remedial-action {
      h2 {
        background: $secondaryBlue90;
        border-radius: 20px;
        color: white;
      }
    }

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .form-elements {
    margin: 11px 30px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    max-width: 950px;

    justify-content: flex-start;
    align-items: flex-start;

    label {
      padding-top: 8px;
      display: flex;
      align-items: center;

      .rc-tooltip-trigger-wrapper {
        margin-left: 6px;
        margin-right: 2px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
      }
    }

    a {
      align-self: center;
    }

    .description, .enkrateia-rfp-response-status-cell {
      padding-top: 8px;
    }

    .description p:first-child {
      margin-top: 0;
    }

    .description p:last-child {
      margin-bottom: 0;
    }

    .react-select__menu {
      min-width: 200px;
    }

    .enkrateia-date-picker-input input {
      width: auto;
    }

    .react-select__single-value.react-select__single-value--is-disabled .enkrateia-ticket-severity-rating {
      .bulb {
        opacity: 0.7;
      }

      .text {
        color: #999999 !important;
      }
    }
  }
}
