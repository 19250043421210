$primaryBlue100: #2c2b63;
$primaryBlue80: #473f77;
$primaryBlue50: #7f719e;
$primaryBlue30: #ac9fbf;

$secondaryBlue100: #343393;
$secondaryBlue90: #343892;
$secondaryBlue80: #5249a5;
$secondaryBlue50: #8b7ac3;
$secondaryBlue30: #b5a8d9;

$green100: #5abc34;
$green80: #83cb71;
$green50: #b0dd9f;
$green30: #d1ecc3;

$darkGreen100: #467636;

$orange100: #f57215;
$orange80: #f78a35;
$orange50: #fab274;
$orange30: #fcd0a6;

$grey100: #788096;
$grey80: #8f94a6;
$grey50: #b5b6c1;
$grey30: #d2d3d9;

$yellow100: #F8C925;
$yellow80: #F9D146;
$yellow50: #FCE492;
$yellow30: #FEF9E5;

$primaryContrastText: #000;

$traroInteractiveElement: #05445E;
$infoEmphasis: #000000;
$infoBackground: #DEF3FC;

$openProject: #05445E;
$inReviewProject: #9B3D13;
$finalProject: #9D7ACB;

$accentLight: #ADCFDE;
$errorTextColor: #d30000;

$traroBrick: #DDB483;
$traroBrickTextFilled: #000;
$traroBrickTextEmpty: #fff;

$traroVoussoir: #9A8384;
$traroSpringer: #D99F50;
$traroKeystone: #B98765;

$scoringRed: #B63333;
$scoringYellow: #D99F50;
$scoringGreen: #67B54C;
$scoringOrange: #F38230;

/* Traro colors */
$traroSecondary500: #05445E;
$traroTertiary500: #97EEFF;
