.enkrateia-table-filter {
  padding-top: 0;

  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    border-radius: 5px;
    padding: 2px 5px 0 5px !important;
  }

  .react-select__control {
    border-radius: 5px !important;
    max-height: 27px;
    min-height: 27px;

    .react-select__value-container,
    .react-select__indicators {
      font-weight: 400;
      padding: 0;
      font-size: 16px;
      line-height: 23px;

      .react-select__indicator {
        padding: 0;
      }
    }

    .react-select__value-container {
      padding: 2px 5px 0 5px !important;
      text-transform: capitalize;
    }
  }

  .react-select__menu {
    width: max-content;
  }
}
