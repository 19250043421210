@import "src/styles/colors";

.enkrateia-element-submitted-modal {
  padding: 50px 50px 45px;

  .title {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      padding-top: 15px;
      padding-bottom: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }

    button {
      min-width: 295px;
    }
  }
}
