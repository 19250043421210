@import "src/styles/colors";

.enkrateia-create-ticket-modal {

  form {
    display: flex;
    flex-direction: column;

    align-items: center;
  }

  .ticket-details {
    margin: 11px 30px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    justify-content: flex-start;
    align-items: center;

    label {
      display: flex;
      align-items: center;

      .rc-tooltip-trigger-wrapper {
        margin-left: 10px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
      }
    }


    & > label {
      margin-right: 30px;
    }

    & > :not(label),
    textarea {
      min-width: 400px;
      max-width: 600px;
    }
  }

  .ticket-summary {
    width: 100%;

    & > div {
      margin: 11px 30px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .enkrateia-link-with-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .success-content {
    padding: 100px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 20px;
      text-transform: uppercase;
      padding: 7px 40px 2px 40px;
    }

    & > div, & > div > a {
      color: #2D2C64;
      display: flex;
      font-size: 24px;
      line-height: 29px;
    }

    a {
      margin-left: 10px;

      svg {
        margin-top: -5px;
      }
    }
  }
}
