@import "src/styles/colors";

.enkrateia-review-rating-bulb {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:first-child {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;

    border-radius: 50%;
  }

  .with-label {
    margin-top: 5px;
    margin-left: 5px;
    font-family: "Hanuman", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    color: white;
  }

  .bulb {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .Green {
    background: $scoringGreen;
    border: 1px solid darken($scoringGreen, 15%);
  }

  .Yellow, .Orange {
    background: $scoringOrange;
    border: 1px solid darken($scoringOrange, 15%);
  }

  .Red {
    background: $scoringRed;
    border: 1px solid darken($scoringRed, 15%);
  }

  .Grey {
    background: $grey30;
    border: 1px solid $grey100;
  }
}
