@import 'src/styles/colors';

.enkrateia-list-project-overview {
  margin-right: 45px;

  h2 {
    padding: 30px;
    color: $primaryBlue100;
    margin: 0;
  }

  table {
    width: 100%;

    &, th, td {
      border-collapse: collapse;
    }

    th {
      font-weight: 400;
      font-size: 12px;
      line-height: 26px;
      color: #4A4A4A;
      vertical-align: bottom;

      button {
        background: none;
        border: none;
        display: flex;
        flex-direction: column;
        color: #4A4A4A;
        align-items: center;
        cursor: pointer;
      }
    }

    td.light {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #4A4A4A;
    }

    th, td {
      text-align: left;

      &.center {
        text-align: center;
      }

      &.number {
        padding-left: 30px;
        padding-right: 50px;
        width: 10px;
      }

      &.name {
        width: 40%;
      }

      &.score,
      &.review {
        width: 160px;
      }

      &.review span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &.status {
        width: 170px;

        button {
          width: 100%;
          max-height: 51px;
        }
      }
    }

    th.status span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }
    }

    td {
      border-top: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;

      font-weight: 400;
      font-size: 22px;
      line-height: 32px;

      color: #4A4A4A;
    }
  }

  .enkrateia-arch-bricks-side-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    overflow: hidden;

    .enkrateia-note {
      display: none;
    }

    &.open {
      max-width: 560px;
      width: 560px;

      .enkrateia-note {
        display: block;
        width: 560px;
        min-width: 560px;
      }
    }
  }
}
