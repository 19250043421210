.enkrateia-create-ticket-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;

  & > svg {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  &:disabled > svg {
    fill: #7D8596 !important;
  }
}

.enkrateia-create-ticket-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
}
