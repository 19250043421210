.markdown-overlay-tooltip {
  &.rc-tooltip .rc-tooltip-content .rc-tooltip-inner {
    background: white;
    color: black;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 90vh;

    p, a {
      font-size: 14px;
    }

    p {
      overflow: hidden;
      text-align: initial;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
