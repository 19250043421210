.enkrateia-ticket-actions {
  margin: 11px 30px;

  h2 {
    margin: 0;
  }

  section {
    margin: 15px 0;

    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
