.enkrateia-header-user-details {
  position: relative;

  .enkrateia-open-menu-button {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    background: none;
    border: none;
    white-space: nowrap;
  }

  img {
    transition: transform 300ms ease-in-out;

    &.open {
      transform: rotate(-180deg);
    }
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    z-index: 999;
  }

  .menu {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 25px;
    left: -20px;
    transition: height 200ms ease-in-out;
    width: calc(100% + 20px);
    box-shadow: 0 0 12px 0 #e0e0e0;
    z-index: 10000;

    &.open {
      height: 51px;
    }

    .enkrateia-button {
      background: white;
      width: 100%;
      border: none;
      white-space: nowrap;
    }
  }
}
