@import "src/styles/colors";

.enkrateia-best-practice-brick {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-items: center;

  padding: 5px;

  border: none;
  outline: none;
  background: none;

  cursor: pointer;

  .enkrateia-loader {
    scale: 0.5;
    max-width: 25px;
    translate: -9px;
  }

  span {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-size: 18px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  span.brick-number {
    font-weight: 900;
    color: $traroBrick;
  }

  span.element-name {
    font-weight: 400;
    color: black;
  }
}
