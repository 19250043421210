@import "src/styles/colors";

.enkrateia-upload-attachment-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .attachment-entry {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      margin-left: 10px;
      color: black;
      font-size: 18px;
      padding-top: 4px;
    }

    button {
      margin-left: 10px;
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .error-label {
    padding: 0.75em 0;
    color: $errorTextColor;
  }
}
