@import "src/styles/colors";

.enkrateia-registration-success-modal {
  padding: 50px 140px 45px;

  .title {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
      font-family: 'Hanuman', sans-serif;
    }
  }

  .details {
    p {
      background: $primaryBlue100;
      color: white;
      padding: 8px 16px;
      font-family: 'Hanuman', sans-serif;
      margin: 16px 0 0 0;
    }

    dl {
      display: grid;
      grid-template-columns: 1fr 2fr;
      padding: 8px 16px;
      border-bottom: 1px solid #dedede;
      margin: 0;

      dt, dd {
        font-family: 'Hanuman', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
      }

      dt {
        text-align: left;
      }
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    span {
      padding: 15px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      max-width: 600px;
      text-align: center;
    }

    button {
      min-width: 100px;
      font-family: 'Hanuman', sans-serif;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 17px;
      font-weight: 400;
      padding: 8px 10px;
    }
  }
}
