@import "src/styles/colors";

.enkrateia-ticket-severity-rating {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    margin-top: 5px;
    margin-left: 5px;
    font-family: "Hanuman", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    color: black;
  }

  & > .bulb {
    width: 25px;
    height: 25px;

    border-radius: 50%;

    &.Not.Assigned, &.Minor {
      background: #7d8596;
      border: 1px solid darken(#7d8596, 15%);
    }

    &.Moderate {
      background: #f38230;
      border: 1px solid darken(#f38230, 15%);
    }

    &.Major {
      background: #B63333;
      border: 1px solid #B63333;
    }
  }

  &.variant-small {
    .text {
      margin-top: 0;
      font-size: 12pt;
      font-family: "Montserrat", serif;
    }

    & > .bulb {
      width: 21px;
      height: 21px;
    }
  }

}
