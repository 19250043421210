.enkrateia-organization-profile-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;

  main {
    flex: 1;
    display: flex;
    flex-direction: column;

    padding: 30px 65px;

    overflow: auto;
    max-width: 1270px;

    .header {
      border-bottom: 1px solid #DEDEDE;
      padding: 17px 45px 5px 30px;

      h2 {
        margin: 0;
      }

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .enkrateia-footer {
    position: sticky;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;

    padding: 10px;
  }
}
