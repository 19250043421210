.enkrateia-timeline {
  display: grid;
  grid-template-columns: auto 14px minmax(0, 1fr);
  grid-row-gap: 40px;
  grid-column-gap: 15px;
  margin: 10px;

  & > .date {
    text-align: right;

    color: #05445E;

    &.placeholder {
      margin-top: 2px;
      background-color: #05445E;
      border-radius: 10px;
      height: 18px;
      animation: 2s timeline-placeholder-pulse infinite;
    }
  }

  & > .content.placeholder {
    margin-top: 2px;
    background-color: #05445E;
    border-radius: 10px;
    animation: 2s timeline-placeholder-pulse infinite;
  }

  & > .date,
  & > .content {
    font-size: 18px;
    line-height: 26px;
  }

  & > .point {
    position: relative;

    .dot {
      margin-top: 4px;
      width: 15px;
      height: 15px;
      background: #05445E;
      border-radius: 15px;
    }

    .line {
      position: absolute;
      width: 1px;
      height: calc(100% + 40px);
      background: #05445E;
      top: 0;
      left: 7px
    }
  }

  & > .point:nth-child(2) .line {
    top: 7px;
  }
  & > .point:nth-last-child(2) .line {
    top: calc(-14px - 40px);
  }

  /* Only one point */
  & > .point:nth-child(2):nth-last-child(2) .line {
    display: none;
  }

  @keyframes timeline-placeholder-pulse {
    0% {
      opacity: 10%;
    }
    50% {
      opacity: 15%;
    }
    100% {
      opacity: 10%;
    }
  }
}
