@import "src/styles/colors";

.enkrateia-vault-checkbox-input {
  padding: 10px 0;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    label {
      margin-left: 10px;
      color: #000;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }

    input:not(:disabled) + label {
      cursor: pointer;
    }
  }

  &.variant-filled div label {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  input[type="checkbox"] {
    appearance: none;
    margin: 0;
    position: relative;

    font: inherit;
    color: inherit;

    width: 23px;
    height: 23px;
    min-width: 23px;
    min-height: 23px;

    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #979797;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2px;

    display: grid;
    place-content: center;
    cursor: pointer;
    transition: 200ms background-color ease-in-out, 200ms border ease-in-out;

    &::before {
      content: '';
      font-family: 'Font Awesome 6 Free', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 8px;
      color: #979797;
      transform: scale(0);
      transform-origin: center;
      transition: 200ms transform ease-in-out;
    }

    &[data-indeterminate='true']::before {
      content: '';
    }

    &:checked,
    &[data-indeterminate='true'] {
      &::before {
        transform: scale(1);
      }
    }

    &:disabled {
      cursor: default;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid #e7e7e7;

      &:checked,
      &[data-indeterminate='true'] {
        border: 1px solid #e7e7e7;
      }
    }
  }

  .error-label {
    color: $errorTextColor;
  }
}
