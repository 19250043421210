@import "src/styles/colors";

.enkrateia-scoring-entry  {
  em {
    font-weight: 600;

    &.Green {
      color: $scoringGreen;
    }

    &.Yellow {
      color: $scoringYellow;
    }

    &.Red {
      color: $scoringRed;
    }
  }
}
