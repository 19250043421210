@import "src/styles/colors";
@import "src/styles/fonts";

.enkrateia-date-range-picker-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  & > * {
    min-width: 100px;
  }

  & > div, input {
    width: 100%;
  }

  &.full-width > *, &.full-width input {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;

    .rc-tooltip-trigger-wrapper {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &.variant-standard {
    label {
      margin-bottom: 7px;
      text-transform: uppercase;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      color: $primaryBlue100;
    }

    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid $accentLight;
      padding: 6px 0;
      font-size: 12pt;
      font-family: 'Hanuman', serif;
    }
  }

  &.variant-filled {
    label {
      margin-bottom: 7px;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000;
    }

    input {
      outline: 0;
      padding: 8px 6px 4px 6px;
      font-size: 12pt;
      font-family: 'Hanuman', serif;

      background: #FFFFFF;
      border: 1px solid #D0D4D9;

      &:disabled {
        background: hsl(0, 0%, 95%);
      }
    }
  }

  .error-label {
    color: $errorTextColor;
  }

  .date-picker {
    position: fixed;
    background: white;
    box-shadow: 3px 5px 8px rgba(168, 176, 185, 0.55);

    .rdp {
      --rdp-accent-color: #{$primaryBlue80};
      --rdp-background-color: #{lighten($primaryBlue30, 20%)};
    }

    .pickers {
      display: flex;
      flex-direction: column;
      align-items: center;


      .buttons-container {
        display: flex;
        flex-direction: row;

        .enkrateia-button {
          padding: 4px 50px 2px;
          width: 150px;
          margin: 0 10px 10px;
        }
      }

      .pickers-inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;

        h3 {
          text-align: center;
          margin-top: 15px;
          margin-bottom: 0;
        }
      }
    }
  }

  .clear-icon {
    outline: none;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 27px;
    height: 27px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background: rgba(72, 72, 72, 0.2);
    }
  }
}
