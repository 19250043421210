.enkrateia-error-overlay {
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  }

  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .error-content {
    display: flex;
    border: 2px solid #ff4b4b;
    border-radius: 4px;
    background: linear-gradient(30deg, #fff7f7, #ffe1e1);
    color: #ff4b4b;
    padding: 24px;
    height: min-content;
    position: relative;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    font-family: Montserrat, sans-serif;

    max-width: 800px;
    overflow: auto;

    h1 {
      font-size: 3em;
      margin: 0;
      font-weight: 400;
    }

    h4 {
      font-weight: 400;
    }

    button {
      margin-top: 20px;
      border: none;
      color: white;
      background-color: #ff4b4b;
      padding: 4px 15px;

      &:hover {
        background-color: darken(#ff4b4b, 10%);
      }

      &:active {
        background-color: darken(#ff4b4b, 20%);
      }
    }

    a {
      color: darken(#ff4b4b, 30%);
    }
  }
}
