@import "src/styles/colors";

.enkrateia-question-statuses-help-modal {
  .title {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }
  }

  .details {
    padding: 0 100px;

    dl {
      display: grid;
      row-gap: 25px;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto auto;

      dt {
        text-align: left;

        font-weight: 700;
        font-size: 16px;
        line-height: 36px;

        color: $primaryBlue100;

        justify-self: stretch;

        button {
          width: 100%;
        }
      }

      dd {
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
        align-self: center;
      }
    }
  }
}
