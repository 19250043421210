.enkrateia-arch {
  background-color: #4A4A4A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  border: none;
  outline: none;
  width: 100%;

  .enkrateia-arch-brick,
  .enkrateia-arch-voussoir,
  .enkrateia-arch-springer,
  .enkrateia-arch-keystone,
  .enkrateia-arch-keystone-text,
  .enkrateia-arch-text
  {
    &:hover {
      opacity: 0.6;
    }
  }

  svg {
    max-width: 1085px;
    max-height: 360px;
  }
}
