.enkrateia-project-overview-page .enkrateia-complete-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 30px 10px;

  color: white;

  span {
    text-align: center;
    padding-top: 10px;
  }

  button {
    width: 100%;
    min-height: 51px;
    display: flex;
    align-items: center;
  }

  a {
    margin-top: 15px;
    color: white;
  }
}
