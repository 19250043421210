.enkrateia-element-history {
  display: flex;
  justify-content: center;

  .history-button {
    background: none;
    border: none;
    outline: none;
    width: auto;

    cursor: pointer;

    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    color: white;

    margin-top: 10px;

    text-decoration: underline;

    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    img {
      margin-top: -10px;
      margin-right: 5px;
    }
  }
}

.enkrateia-element-history-modal {
  padding: 0;
}
