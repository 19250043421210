/* league-spartan-100 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 100;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-200 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-300 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-regular - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-500 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-600 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-700 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-800 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* league-spartan-900 - latin */
@font-face {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/LeagueSpartan/league-spartan-v6-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}




/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local(''),
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Montserrat/montserrat-v25-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



/* hanuman-100 - latin */
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 100;
  src: local(''),
  url('../assets/fonts/Hanuman/hanuman-v22-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Hanuman/hanuman-v22-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanuman-300 - latin */
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../assets/fonts/Hanuman/hanuman-v22-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Hanuman/hanuman-v22-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanuman-regular - latin */
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/Hanuman/hanuman-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Hanuman/hanuman-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanuman-700 - latin */
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/Hanuman/hanuman-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Hanuman/hanuman-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanuman-900 - latin */
@font-face {
  font-family: 'Hanuman';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('../assets/fonts/Hanuman/hanuman-v22-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../assets/fonts/Hanuman/hanuman-v22-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

/* Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
