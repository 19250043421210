.enkrateia-announcement {
  background: #FFF2C5;
  border-radius: 6px;
  position: relative;
  padding: 30px;
  width: 100%;

  margin-top: 10px;
  margin-bottom: 10px;

  .data {
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;

    font-weight: 700;
    font-size: 21px;

    background: none;
    border: none;

    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding-top: 1px;

    border-radius: 50%;

    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }
}
