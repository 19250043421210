@import "src/styles/colors";

.enkrateia-update-response-status-action-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .enkrateia-loader-overlay {
    border-radius: 10px;
  }

  .status-switch-error {
    margin: 0;
    padding: 0 15px 15px 15px;
    color: $errorTextColor;
    width: 100%;
  }

  .confirm {
    margin-top: 20px;
    margin-bottom: 20px;

    padding: 13px 70px 7px 70px;
  }

  .form-element-container {
    display: flex;
    flex-direction: row;
    padding: 15px;
    gap: 30px;
    width: 100%;

    .enkrateia-select-input .enkrateia-review-rating-bulb .with-label {
      color: black !important;
      font-size: initial;
    }

    .enkrateia-kliparo-response-status {
      &.active {
        outline: 2px black solid;
      }

      &.inactive:hover {
        outline: 1px black solid;
        outline-offset: 2px;
      }

      &.inactive {
        opacity: 0.7;
      }
    }

    .inner-status-content {
      white-space: nowrap;
    }

    .enkrateia-select-input {
      width: 100%;
    }
  }
}
