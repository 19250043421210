@import "src/styles/colors";

.enkrateia-bricks-elements-description {
  padding: 15px 30px;

  display: flex;
  align-items: flex-start;

  .main {
    flex: 1;
  }

  & > *,
  p {
    margin: 0;

    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    ul, ol {
      padding-inline-start: 45px;
    }

    ol, ul, li {
      margin: 0;
    }
  }

  h2, h3 {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .enkrateia-note {
    min-width: 315px;
    max-width: 315px;
    margin-left: 30px;

    strong {
      font-weight: 700;
    }
  }
}
