.enkrateia-kliparo-section-row {
  cursor: pointer;

  td {
    border-top: 1px solid #D8D8D8 !important;
    border-bottom: none !important;

    & > div {
      display: flex;
      align-items: center;
    }
  }

  &:last-child td {
    border-bottom: 1px solid #D8D8D8;
  }

  &:not(.question) td {
    height: 75px;
  }

  .id svg {
    transition: transform 200ms ease-in-out;
    margin-right: 15px;
  }

  &.expanded {
    .id svg {
      transform: rotate(90deg);
    }
  }

  &.question td {
    border-top: none !important;
    border-spacing: 0;
    padding: 0;

    & > div {
      transition: height 200ms ease-in-out;
      height: 0;
      overflow: hidden;
    }
  }

  &.question.expanded td > div {
    height: 75px;
    border-spacing: 2px;
    padding: 1px;
    border-top: 1px solid #D8D8D8 !important;
  }
}
