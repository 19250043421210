.enkrateia-dashboard-assessments {
  display: flex;
  flex-direction: column;
  width: 100%;

  .enkrateia-table {
    align-self: flex-start;
  }

  .enkrateia-select-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h2 {
      margin: 0;
    }
  }
}
