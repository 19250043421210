.change-ticket-status-modal {
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5px 45px 45px 40px;

    .enkrateia-textarea-input {
      width: 100%;
    }

    button {
      margin-top: 20px;
    }
  }
}
