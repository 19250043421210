.enkrateia-previous-next-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .previous-next-content {
    flex: 1;
  }

  a, span {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  span {
    color: #363636 !important;
  }

  a {
    color: black !important;

    &:hover:not(.disabled) {
      text-decoration: underline;
    }
  }
}
