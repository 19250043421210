@import "src/styles/colors";

.enkrateia-rfp-response-status-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .dot {
    width: 12px;
    height: 12px;

    border-radius: 50%;
    margin-top:-4px;
    margin-right: 10px;
  }
  & > .text {
    font-weight: 700;
  }

  &.Open {
    color: $secondaryBlue90;

    & > .dot {
      background: $secondaryBlue90;
    }
  }


  &.Final {
    color: $grey100;

    & > .dot {
      background: $grey100;
    }
  }


  &.In.Review {
    color: $darkGreen100;

    & > .dot {
      background: $darkGreen100;
    }
  }

  &.Application.Withdrawn {
    color: #adcfde;

    & > .dot {
      background: #adcfde;
    }
  }

  &.Closed {
    color: black;

    & > .dot {
      background: black;
    }
  }
}
