@import "src/styles/colors";

.enkrateia-scoring {
  background-color: $accentLight;
  padding: 15px 0;

  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;

    text-align: center;

    color: $primaryBlue100;

    &.score {
      font-weight: 700;
      font-size: 42px;
    }
  }

  button.help {
    padding: 5px;
    font-weight: 400;
    font-size: 16px;

    background: none;
    border: none;

    display: inline-flex;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 100%;

    img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .scoring-rubric {
    padding: 10px 15px;

    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
  }
}
