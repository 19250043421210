.enkrateia-element-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    min-height: 120px;
    position: relative;

    main {
      width: 100%;
      position: relative;
      overflow-y: auto;

      & > div {
        width: 100%;
        max-width: 750px;
        position: relative;
      }

      .enkrateia-arch-bricks-side-panel {
        max-height: 100%;
        overflow: hidden;
      }
    }
  }
}
