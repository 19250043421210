.enkrateia-change-assessment-state-modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
  align-items: center;

  .enkrateia-select-input .enkrateia-review-rating-bulb .with-label {
    color: black !important;
    font-size: initial;
  }

  .enkrateia-loader-overlay {
    border-radius: 10px;
  }

  .enkrateia-select-input.variant-filled .react-select__value-container {
    padding: 4px 6px 4px 6px;
  }

  .elements {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;

    & > label, & > span {
      padding-top: 8px;
    }
  }
}
