@import "src/styles/colors";

.enkrateia-kliparo-modal {
  min-width: 520px;

  background: rgba(255, 255, 255, 0.95);
  border: 3px solid $primaryBlue100;
  box-shadow: 2.11309px 4.53154px 8px rgba(168, 176, 185, 0.541176);
  border-radius: 10px;

  .modal-title {
    border-bottom: 3px solid $primaryBlue100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    h2 {
      margin: 8px 8px 0 15px;
      font-size: 24px;
      line-height: 29px;
      font-weight: 400;
      color: $primaryBlue100;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a {
      margin: 0 0 0 10px;
      font-size: 24px !important;
      line-height: 29px;
      font-weight: 400;
      color: $primaryBlue100;
      text-decoration: underline !important;

      svg {
        margin-top: -3px;
        margin-bottom: 3px;
      }
    }

    .close-button {
      outline: none;
      margin: 0 8px 0 15px;
      background: transparent;
      border: none;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover svg path {
        fill: $primaryBlue50;
      }

      &:active svg path {
        fill: $primaryBlue80;
      }
    }
  }
}
