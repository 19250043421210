.kliparo-sections-list {
  .review-rating {
    width: 140px;

    & > div {
      justify-content: center;
    }
  }

  .tickets {
    width: 160px;

    .rc-tooltip-trigger-wrapper {
      margin-bottom: -8px;
    }

    & > div {
      justify-content: center;

      & > a {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        text-decoration: none;

        display: flex;
        align-items: center;
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 5px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .response-status {
    width: 200px;

    & > div {
      justify-content: center;
    }
  }

  .section-name {
    width: min(calc(0.45 * 100vw), 900px);
  }

  th div {
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
      margin-left: 10px;
    }
  }
}
