.enkrateia-footer {
  clear: both;
  display: inline;
  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-wrap: none;
  white-space: nowrap;

  span {
    padding: 0 8px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    &:hover {
      text-decoration: underline;
    }
  }
}
