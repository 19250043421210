.enkrateia-login-page {
  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .enkrateia-text-input {
      margin-top: 40px;
    }

    button {
      margin: 30px 15px;
    }
  }

  a {
    margin-top: 30px;
  }
}
