.enkrateia-ellipsis-text-cell {
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  position: relative;

  .full-width {
    display: none;
  }

  &.full-text:hover {
    overflow: visible;

    .full-width {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background: #f2f2f2;

      display: block;
      overflow: visible;
    }
  }
}
