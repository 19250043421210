.enkrateia-loader {
  position: relative;
  font-size: 5px;
  min-height: 3em;
  width: 12em;

  .enkrateia-loader-inner {
    color: inherit;
    height: 1em;
    margin: auto 1em;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    top: -2em;
    left: 3.75em;

    &, &:before, &:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: enkrateia-loader-keyframes 1.8s infinite ease-in-out;
      animation: enkrateia-loader-keyframes 1.8s infinite ease-in-out;

      content: '';
      position: absolute;
    }

    &:before {
      left: -3.5em;
      top: 0;
      animation-delay: -0.16s;
    }

    &:after {
      left: 3.5em;
      top: 0;
      animation-delay: 0.16s;
    }

    @-webkit-keyframes enkrateia-loader-keyframes {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
    @keyframes enkrateia-loader-keyframes {
      0%,
      80%,
      100% {
        box-shadow: 0 2.5em 0 -1.3em;
      }
      40% {
        box-shadow: 0 2.5em 0 0;
      }
    }
  }
}
