@import "src/styles/colors";

.enkrateia-button {
  background: none;
  border-radius: 6px;
  padding: 13px 20px 7px 20px;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  justify-content: center;

  transition: background ease-in-out 100ms, border ease-in-out 100ms, color ease-in-out 100ms;

  &:not(:disabled) {
    cursor: pointer;

    &:hover:not(:disabled) {
      background: #f0f0f099;
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  &.variant-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $primaryBlue100;
    padding: 6px 20px 6px 20px;

    border: none;
    background: transparent;

    &:not(:disabled) {
      cursor: pointer;

      &:hover:not(:disabled) {
        background: transparent;
        color: $primaryBlue80;
      }
    }

    &:disabled {
      color: #7D8596;
    }
  }

  &.variant-default {
    &:disabled {
      svg {
        fill: #D8DADF;
      }

      color: #D8DADF;
      border: 1px solid #D8DADF;
    }
  }

  &.variant-primary {
    background: $yellow100;
    border: 1px solid $yellow100;
    color: $primaryContrastText;

    &:not(:disabled) {
      &:hover:not(:disabled) {
        background: darken($yellow100, 10%);
      }

      &:active:not(:disabled) {
        background: darken($yellow100, 20%);
      }
    }

    &:disabled {
      color: #7D8596;
      border: 1px solid #D8DADF;
      background: #D8DADF;
    }
  }

  &.variant-danger {
    color: #fff;
    border: 1px solid transparent;
    $bgColor: #B63333;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 10%) !important;
    }
  }

  &.variant-secondary {
    color: #014F71;
    border: 3px solid #014F71;
    $bgColor: #fff;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-secondary-filled {
    color: white;
    border: 1px solid #014F71;
    $bgColor: #014F71;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-tertiary,
  &.variant-tertiary-thin {
    color: $secondaryBlue90;
    border: 3px solid $secondaryBlue90;
    $bgColor: #fff;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-tertiary-thin {
    border: 1px solid $secondaryBlue90;
  }

  &.variant-tertiary-filled {
    color: white;
    border: 3px solid $secondaryBlue90;
    $bgColor: $secondaryBlue90;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-grey-filled {
    color: white;
    border: 3px solid $grey100;
    $bgColor: $grey100;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-light-grey-filled {
    color: black;
    border: 3px solid #D8DADF;
    $bgColor: #D8DADF;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 2%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }
  }

  &.variant-black-filled {
    color: white;
    border: 3px solid black;
    $bgColor: black;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: lighten($bgColor, 10%) !important;
    }

    &:active:not(:disabled) {
      background: lighten($bgColor, 20%) !important;
    }
  }

  &.variant-black {
    color: black;
    border: 1px solid black;
    $bgColor: white;
    padding: 15px 20px 9px 20px;

    background: white;

    &:hover:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 10%) !important;
    }
  }

  &.variant-green-filled {
    color: white;
    border: 3px solid #339D09;
    $bgColor: #339D09;

    background: $bgColor;

    &:hover:not(:disabled) {
      background: darken($bgColor, 5%) !important;
    }

    &:active:not(:disabled) {
      background: darken($bgColor, 10%) !important;
    }
  }

  &.full-width {
    width: 100%;
  }
}
