.enkrateia-project-overview {
  width: 100%;
  min-height: 100%;
  position: relative;

  .head {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    padding: 30px;
    margin-right: 45px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    max-width: 1650px;

    .title {
      h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        margin: 0;
      }

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: inline-flex;
        text-decoration: none;

        img {
          margin-bottom: 1px;
          margin-right: 3px;
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .body {
    margin-top: 30px;
  }
}
