@import "src/styles/colors";

@mixin tooltipVariant($background, $border, $color) {
  &.rc-tooltip-placement-top,
  &.rc-tooltip-placement-topLeft,
  &.rc-tooltip-placement-topRight {
    padding: 0 5px 0 5px;

    .rc-tooltip-arrow {
      border-top-color: $border;
    }
  }

  &.rc-tooltip-placement-right,
  &.rc-tooltip-placement-rightTop,
  &.rc-tooltip-placement-rightBottom {
    padding: 0 5px 0 5px;

    .rc-tooltip-arrow {
      border-right-color: $border;
    }
  }

  &.rc-tooltip-placement-bottom,
  &.rc-tooltip-placement-bottomLeft,
  &.rc-tooltip-placement-bottomRight {
    padding: 5px 0 5px 0;

    .rc-tooltip-arrow {
      border-bottom-color: $border;
    }
  }

  &.rc-tooltip-placement-left,
  &.rc-tooltip-placement-leftTop,
  &.rc-tooltip-placement-leftBottom {
    padding: 0 5px 0 5px;

    .rc-tooltip-arrow {
      border-left-color: $border;
    }
  }

  .rc-tooltip-inner {
    background: $background;
    color: $color;
    border: 1px solid $border;

    p {
      text-align: center;
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}



.rc-tooltip {
  opacity: 1;
  max-width: 500px;

  &.variant-error {
    @include tooltipVariant(#FF918A, $errorTextColor, $errorTextColor)
  }

  &.variant-dark {
    @include tooltipVariant($primaryBlue80, $primaryBlue100, $primaryBlue30)
  }

  &.variant-default {
    @include tooltipVariant($grey30, $grey30, $primaryBlue100)
  }
}

.rc-tooltip-trigger-wrapper > *:disabled {
  pointer-events: none;
}
