.enkrateia-response-status-help {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 40px;
  grid-row-gap: 18px;

  align-items: center;
  padding: 15px 21px;
  max-width: 830px;

  label {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: black;
  }
}
