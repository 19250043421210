.enkrateia-arch-text {
  fill: white;

  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}
