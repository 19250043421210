.enkrateia-create-review-tickets-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  gap: 16px;
  padding: 16px 48px;
}

.enkrateia-create-review-tickets-modal .enkrateia-kliparo-modal {
  min-width: 320px;
}
