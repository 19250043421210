.enkrateia-auto-save-indicator {
  padding: 2px 10px 0 10px;
  color: #999;
  border-bottom: 1px solid hsl(0deg, 0%, 92%);
  border-left: 1px solid hsl(0deg, 0%, 92%);
  border-right: 1px solid hsl(0deg, 0%, 92%);
  background: white;

  .enkrateia-svg-icon {
    margin-right: 10px;
  }

  .syncing {
    animation: rotation 1s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-359deg);
      }
    }
  }
}
