@import "src/styles/colors";
@import "src/styles/fonts";

.enkrateia-date-picker-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  & > * {
    min-width: 100px;
  }

  & > div, input {
    width: 100%;
  }

  &.full-width > *, &.full-width input {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;

    .rc-tooltip-trigger-wrapper {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &.variant-standard {
    label {
      margin-bottom: 7px;
      text-transform: uppercase;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      color: $primaryBlue100;
    }

    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid $accentLight;
      padding: 6px 0;
      font-size: 12pt;
      font-family: 'Hanuman', serif;
    }
  }

  &.variant-filled {
    label {
      margin-bottom: 7px;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000;
    }

    input {
      outline: 0;
      padding: 8px 6px 4px 6px;
      font-size: 12pt;
      font-family: 'Montserrat', serif;

      background: #FFFFFF;
      border: 1px solid #D0D4D9;

      &:disabled {
        background: hsl(0, 0%, 95%);
      }
    }
  }


  &.variant-raw {
    label {
      display: none;
    }

    input {
      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 31px;

      background: transparent;
      border: none;
      color: white;
      resize: none;
      outline: none;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      &:disabled {
        background: hsl(0, 0%, 95%);
      }
    }

    .clear-icon {
      top: 6px;
      right: 8px;
    }
  }

  .error-label {
    color: $errorTextColor;
  }

  .date-picker {
    position: fixed;
    background: white;
    box-shadow: 3px 5px 8px rgba(168, 176, 185, 0.55);

    .rdp {
      --rdp-accent-color: #{$primaryBlue80};
      --rdp-background-color: #{lighten($primaryBlue30, 20%)};
    }
  }

  input:disabled {
    color: #999999 !important;
  }

  .clear-icon {
    outline: none;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 27px;
    height: 27px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background: rgba(72, 72, 72, 0.2);
    }
  }
}
