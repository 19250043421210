.enkrateia-comment {
  display: flex;
  flex-direction: row;

  .avatar {
    max-width: 50px;
    min-width: 50px;

    max-height: 50px;
    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #F0F0F0;

    &.RER {
      background-color: #014F71;
      color: white;
    }

    &.PRT {
      background-color: #F8C925;
      color: black;
    }

    font-weight: 700;
    font-size: 18px;

    border-radius: 50%;
    padding-top: 2px;
  }

  .comment {
    border: 1px solid #ADCFDE;
    border-radius: 4px;
    padding: 15px;
    margin-left: 15px;

    display: flex;
    flex-direction: column;

    .name {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;

      color: #000000;
    }

    .time {
      font-size: 16px;
      line-height: 23px;

      color: #4A4A4A;
    }

    .content {
      font-size: 18px;
      line-height: 26px;

      margin-top: 2px;

      color: #000000;
    }
  }
}
