.enkrateia-organization-page-user-details {
  h2 {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .enkrateia-table {
    margin-top: 5px;
  }
}
