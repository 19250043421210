.enkrateia-kliparo-side-bar {
  & > .side-bar-content {
    padding: 33px 16px 0 40px;

    display: flex;
    flex-direction: column !important;

    & > * {
      margin-bottom: 25px;
    }

    a {
      color: white;
    }

    .middle-panels {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: -25px;

      & > * {
        min-width: 130px;
        margin-top: 24px;
        margin-right: 18px;
      }

      .enkrateia-project-info-panel.variant-kliparo .value .enkrateia-loader {
        max-height: 10px;
        font-size: 5px !important;
      }
    }

    .days-left {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: -24px;
      align-items: flex-start;

      & > * {
        margin-top: 24px;
      }

      .close-date {
        flex: 5;
        margin-right: 18px;
      }

      .days-left-count {
        flex: 3;
        margin-top: 18px;
      }
    }

    .enkrateia-date-picker-input.variant-raw input:disabled {
      background: none;
    }
  }

  .entry-button-with-help, .rc-tooltip-trigger-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-left: 16px;
    }
  }
}
