.enkrateia-element-comments {
  padding: 30px;
  position: relative;

  .comments {
    position: relative;
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: 15px;
    }
  }

  .no-comments {
    display: flex;
    justify-content: center;

    padding-top: 60px;

    font-size: 26px;
  }
}
