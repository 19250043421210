.enkrateia-ticket-linked-criterion-field {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  a {
    margin-left: 10px;
  }
}
