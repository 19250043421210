.enkrateia-arch-completion-status {
  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  fill: white;

  tspan:first-of-type {
    font-weight: 700;
  }
}
