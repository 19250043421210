@import "src/styles/colors";

.enkrateia-ticket-comment {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  border-radius: 4px;


  &.internal {
    align-self: flex-end;

    .content {
      background: rgba(153, 155, 200, 0.19);
      border: none;
    }
  }

  &.my-comment {
    .avatar {
      background: $yellow100;
      color: black;
    }
  }

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .comment-publicity {
    color: #404040;
    font-family: Hanuman, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 6px;
    border: 1px solid #404040;
    padding: 3px 6px 1px 6px;
  }

  .avatar {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: $secondaryBlue90;

    font-weight: 700;
    font-size: 18px;
    padding-top: 3px;

    color: white;
  }

  .content {
    border: 1px solid #ADCFDE;
    border-radius: 4px;

    flex: 1;
    min-width: 660px;
    max-width: 660px;

    margin-left: 20px;
    padding: 10px 15px;

    h3, p {
      margin: 0;
    }

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }

    .timestamp {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #4A4A4A;
    }

    .comment {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin-top: 5px;
    }
  }

  .attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;

    span {
      color: black;
      margin-left: 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      padding-top: 2px;
    }

    .attachment-icon {
      background-color: $secondaryBlue90;
      border-radius: 5px;
      padding: 4px;
    }
  }
}
