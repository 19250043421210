@import "src/styles/colors";

.enkrateia-question-response {
  padding: 30px;

  .enkrateia-note {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: $infoBackground;
    padding: 15px 125px 15px 15px;

    img {
      margin-right: 10px;
    }

    .question-1 {
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #05445E;

      & > p {
        margin: 0;
      }
    }

    .question-2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: black;
      margin-top: 20px;
      margin-bottom: 0;

      & > p {
        margin: 0;
      }
    }
  }

  .form-data {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    textarea {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      width: 100%;
    }

    span {
      font-size: 16px;
      line-height: 20px;

      text-align: right;

      color: #9B3D13;
    }
  }

  .score {
    display: flex;
    justify-content: flex-end;

    .enkrateia-text-input {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      label {
        text-align: right;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        input {
          border: 1px solid #ADCFDE;
          padding: 14px 10px 10px 10px;
          width: 300px;

          font-weight: 400;
          font-size: 18px;
          line-height: 26px;

          text-align: right;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
        }
      }
    }
  }

  .enkrateia-question-status-button {
    position: absolute;
    top: -60px;
    right: -200px;
  }
}
