.enkrateia-history-modal {
  min-width: 450px;

  .title {
    display: flex;
    justify-content: center;
    padding: 20px;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }

    border-bottom: 1px solid #dedede;
  }

  .details {
    padding: 40px;
    overflow: auto;
    max-height: 480px;
  }

  .no-history {
    display: flex;
    justify-content: center;
  }
}
