@import "src/styles/colors";

.enkrateia-ticket-status-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .dot {
    width: 12px;
    height: 12px;

    border-radius: 50%;
    margin-top:-4px;
    margin-right: 10px;
  }
  & > .text {
    font-weight: 700;
  }

  &.Open {
    color: $secondaryBlue90;

    & > .dot {
      background: $secondaryBlue90;
    }
  }

  &.Closed {
    color: #000000;

    & > .dot {
      background: #000000;
    }
  }
}
