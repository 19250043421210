.enkrateia-kliparo-element-side-bar {
  .enkrateia-project-info-panel.variant-kliparo .value .enkrateia-loader {
    max-height: 10px;
    font-size: 5px !important;
  }

  .enkrateia-previous-next-navigation {
    align-items: flex-start;

    & > :not(.previous-next-content) {
      padding-top: 3px;
    }
  }
}
