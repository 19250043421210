@import "src/styles/colors";

.enkrateia-ticket-summary {
  border-radius: 6px;
  width: 195px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  .enkrateia-loader {
    margin: 15px;
  }

  span.number {
    font-weight: 700;
    font-size: 32px;
    line-height: 47px;
  }

  span.label {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  &.open {
    border: 1px solid $primaryBlue100;
    background: white;
    color: black;
  }

  &.assigned {
    border: 1px solid $secondaryBlue100;
    background: $secondaryBlue100;
    color: white;
  }

  &.past {
    border: 1px solid $scoringRed;
    background: $scoringRed;
    color: white;
  }

  &.closed {
    border: 1px solid $grey100;
    background: $grey100;
    color: white;
  }
}
