@import "src/styles/colors";

.enkrateia-brick-panel {
  display: flex;
  flex-direction: row;
  padding: 15px;

  .enkrateia-loader {
    margin-right: 15px;
    scale: 0.5;
    max-width: 25px;
    translate: -15px;

    .enkrateia-loader-inner {
      color: white;
    }
  }

  & > img {
    margin-top: -3px;
    margin-right: 15px;
  }

  & > span.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  width: 100%;

  background: transparent;
  border: none;
  border-bottom: 1px solid #979797;

  &.interactive {
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  span.number {
    color: $traroBrick;

    font-weight: 900;
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 10px;
  }

  span.description {
    color: white;
    text-align: start;

    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }
}
