.enkrateia-new-assessment-modal {
  .pages {
    min-width: 580px;
    max-width: 580px;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-elements {
    width: 100%;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    justify-content: flex-start;
    align-items: flex-start;

    label {
      margin-top: 5px;
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
  }

  .success-step {
    .submit-container {
      margin-top: 20px;
      margin-bottom: 30px;

      button {}
    }
  }

  .disclaimer {
    padding: 10px;

    textarea {
      resize: none;
    }
  }

  .info {
    border-top: 1px solid black;
    padding: 12px 0;
    margin: 11px 15px 5px 15px;
    text-align: justify;

    &.bottom {
      border-top: none;
      border-bottom: 1px solid black;
    }

    &, a {
      font-size: 16px;
      line-height: 26px;
    }

    a {
      font-weight: bold;
      color: black;
    }
  }
}
