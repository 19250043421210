@import "src/styles/colors";

.enkrateia-sidebar {
  position: relative;

  &.variant-traro {
    background-color: $traroSecondary500;
  }

  &.variant-kliparo {
    background-color: $grey100;
  }

  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  transition: width ease-in-out 200ms;

  &.open {
    width: 500px !important;
  }

  &.closed {
    width: 45px;
  }

  display: flex;
  flex-direction: column;

  .footnote {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

    a {
      color: white;
    }
  }

  .footnote {
    & > * {
      margin: 3px;
    }

    margin: 10px 13px;
  }

  .enkrateia-sidebar-collapse-button {
    position: absolute;
    top: 0;
    right: 0;

    padding: 10px 12px 0 10px;

    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    .enkrateia-svg-icon {
        transition: transform 0.3s ease-in-out;
    }

    &:hover, &:focus-visible {
      svg.enkrateia-svg-icon path {
        fill: #d3d3d3 !important;
      }
    }
  }

  &.open .enkrateia-sidebar-collapse-button .enkrateia-svg-icon {
    transform: rotate(180deg);
  }

  .enkrateia-sidebar-button {
    color: white;
    border: 1px solid #000000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 100%;

    svg {
      margin-right: 12px;
    }

    &:not(:disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      &:active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
