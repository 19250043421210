.enkrateia-kliparo-response-status {
  width: 170px;
  height: 51px;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.small {
    width: 63px;
  }

  span {
    margin-top: 4px;
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
  }
}
