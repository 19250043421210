.enkrateia-element-comment-entry {
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .enkrateia-textarea-input,
    .enkrateia-textarea-input textarea {
      width: 100%;
    }


    .enkrateia-textarea-input {
      padding-top: 0;
    }
  }
}
