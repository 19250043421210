@import "src/styles/colors";

.enkrateia-cookie-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 2000;
  background: rgba(125, 133, 150, 0.6);

  .enkrateia-cookie-banner-content {
    position: relative;
    width: 600px;
    top: 0;
    left: 0;
    background-color: $primaryBlue100;
    padding: 50px;
    height: 100vh;

    display: flex;
    flex-direction: column;

    .terms {
      flex: 1;
      margin-right: -50px;
      padding-right: 50px;
      overflow: auto;
    }

    h2 {
      margin: 0;
      color: #fff;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
    }

    p {
      color: white;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;

      a {
        color: #ffffff;
      }
    }

    .buttons {
      margin-top: 30px;

      button {
        width: 300px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    .info {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      img {
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }
}
