@import "src/styles/colors";

.enkrateia-project-submitted-modal {
  padding: 50px 0 45px;

  .title {
    display: flex;
    justify-content: center;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin: 0;
    }
  }

  .details {
    border-bottom: 1px solid #dedede;
    padding: 0 200px;

    dl {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto auto;

      dt {
        text-align: left;

        font-weight: 700;
        font-size: 16px;
        line-height: 36px;

        color: $primaryBlue100;
      }

      dd {
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
      }
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      padding-top: 15px;
      padding-bottom: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      max-width: 600px;
      text-align: center;
    }

    button {
      min-width: 295px;
    }
  }
}
