.enkrateia-ticket-comment-toggle-publish-flag {
  display: flex;
  flex-direction: row;
  align-items: center;

  .rc-tooltip-trigger-wrapper {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
