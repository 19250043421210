.enkrateia-ticket-comments {
  section.comments {
    position: relative;
    display: flex;
    flex-direction: column;

    max-width: 950px;

    margin: 15px 0 15px 30px;
  }

  .comment-form {
    max-width: 950px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 30px;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .enkrateia-textarea-input, .enkrateia-upload-attachment-display {
        min-width: 660px;
        max-width: 660px;
      }

      .comment-and-attachment {
        display: flex;
        flex-direction: column;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
      }

      button {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
}
