@import "src/styles/colors";

.enkrateia-tabs {
  display: flex;
  flex-direction: column;

  .enkrateia-tabs-header {

    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: stretch;
    padding-right: 16px;
    padding-left: 16px;

    .enkrateia-tab-name {
      flex: 1;
      padding: 30px 0 15px 0;
      border: none;
      background: none;
      cursor: pointer;
      background: white;
      position: relative;

      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: $traroInteractiveElement;

      display: flex;
      flex-direction: column;
      align-items: center;

      transition: border-top-color 100ms ease-in-out, border-bottom-color 100ms ease-in-out, border-left-color 100ms ease-in-out, border-right-color 100ms ease-in-out;

      span {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
      }

      span:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 5px;
        bottom: -5px;
        background-color: $traroInteractiveElement;
        transition: width 200ms ease-in-out;
      }

      &:hover span:after {
        background-color: $traroInteractiveElement;
        width: 100%;
      }

      &.active span {
        color: $primaryBlue100;

        &:after {
          background-color: $traroInteractiveElement;
          width: 100%;
        }
      }
    }
  }

  .enkrateia-tabs-content {
    position: relative;
    width: 100%;
    min-height: 100px;
  }
}
