.enkrateia-submit-assessment-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 20px;
  align-items: flex-start;
  max-width: 700px;

  .enkrateia-loader-overlay {
    border-radius: 10px;
  }

  .elements {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;

    & > label, & > span {
      padding-top: 8px;
    }
  }

  .submit-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
}

.enkrateia-submit-assessment-modal-success .modal-content {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
    font-size: 20px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;

    a {
      font-size: 20px;
      color: #000;
    }
  }

  button {
    margin-top: 1em;
  }
}
