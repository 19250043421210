.enkrateia-upload-attachment {
  display: flex;
  flex-direction: row;
  align-items: center;

  button .enkrateia-svg-icon {
    margin-left: -5px;
    margin-right: 10px;
    margin-top: -2px;
  }

  .rc-tooltip-trigger-wrapper {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
