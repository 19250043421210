.enkrateia-powered-by {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: 'League Spartan', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: white;

  img {
    height: 36px;
    margin-bottom: 5px;
  }
}
