@import "src/styles/colors";

.enkrateia-kliparo-element-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;


  .back-to-section-list {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;

    main {
      width: 100%;
      position: relative;
      overflow-y: auto;

      display: flex;
      flex-direction: column;

      & > div {
        width: 100%;
        position: relative;
      }

      .enkrateia-arch-bricks-side-panel {
        max-height: 100%;
        overflow: hidden;
      }
    }
  }

  .enkrateia-tabs .enkrateia-tabs-header .enkrateia-tab-name {
    padding: 10px;
    color: $secondaryBlue90;

    span:after {
      background-color: $secondaryBlue90;
    }

    &:hover span:after {
      background-color: $secondaryBlue90;
    }

    &.active span {
      color: $secondaryBlue100;

      &:after {
        background-color: $secondaryBlue90;
      }
    }
  }

  .side-bar-content {
    padding: 33px 16px 0 40px;

    display: flex;
    flex-direction: column !important;

    & > * {
      margin-bottom: 25px;
    }

    a {
      color: white;
    }

    .middle-panels {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: -25px;

      & > * {
        min-width: 130px;
        margin-top: 24px;
        margin-right: 18px;
      }
    }
  }

  .enkrateia-element-background,
  .enkrateia-kliparo-question-response {
    max-width: 750px;
  }

  .enkrateia-tabs-header {

    max-width: 900px;
  }

  .enkrateia-create-ticket-button {
    margin-bottom: 0;
    margin-top: -4px;
    flex: 1.5;
    justify-content: flex-end;
  }

  .ticket-tab-count {

  }

  .mark-as-complete-error {
    text-align: center;
    color: $errorTextColor;

    margin-top: 10px;
    margin-bottom: 15px;
  }

  .tabs-container {
    max-height: calc(100vh - 120px);
    overflow: hidden;

    .enkrateia-tabs, .enkrateia-tabs-content {
      max-height: 100%;
    }

    .enkrateia-tabs-content {
      overflow: auto;
    }
  }
}
