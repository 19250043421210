.enkrateia-arch-bricks-side-panel {
  background-color: #4A4A4A;
  display: flex;
  flex-direction: column;

  &.interactive {
    z-index: 2;
  }

  max-width: 45px;
  min-width: 45px;
  overflow: hidden;

  transition: width 250ms ease-in-out, max-width 250ms ease-in-out;

  &.open {
    max-width: 400px;
    width: 400px;

    .title {
      h2 {
        opacity: 1;
        width: 255px;
        padding-left: 15px;
      }

      img {
        transform: rotate(0deg);
      }
    }

    & > .content {
      opacity: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .title {
    padding-right: 10px;
    width: 100%;

    border: none;
    outline: none;

    background: transparent;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    h2 {
      color: white;
      font-weight: 400;
      font-size: 22px;

      white-space: nowrap;
      flex: 1;

      overflow: hidden;

      width: 0;
      opacity: 0;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      transition: width 250ms ease-in-out, opacity, 100ms ease-in-out;


      text-align: start;
    }

    img {
      margin-bottom: 5px;
      margin-right: 5px;
      transform-origin: center;
      transform: rotate(180deg);
      transition: transform 200ms ease-in-out;
    }
  }

  & > div.content {
    opacity: 0;
    transition: opacity 250ms ease-in-out;

    display: flex;
    flex-direction: column;
  }
}

.enkrateia-arch-bricks-side-panel-interactive-backdrop {
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  right: 0;
  opacity: 0;

  transition: left ease-in-out 10ms, opacity ease-in-out 250ms;

  &.open {
    opacity: 0.5;
    left: 0;
  }

  background-color: white;
  z-index: 1;
}
